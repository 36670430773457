import { ga4NullEventFields, getLocation } from './helpers';
import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module';
import {
  IGAEvent,
  IGA4Event,
  IGA4EventData,
  GA4Events,
  GA4Actions,
  GA4Pages,
  GA4Sections,
} from './interfaces';

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || '',
  auth: process.env.REACT_APP_GTM_AUTH || '',
  preview: process.env.REACT_APP_GTM_ENV_ID || '',
};

if (process.env.NODE_ENV !== 'development') {
  TagManager.initialize(tagManagerArgs);
}

const appVersion = process.env.REACT_APP_APP_VERSION || 'dev';
export default class GaEventManager {
  private static _buyerId: number | undefined = undefined;
  private static inputDelays: Record<string, number> = {};
  private static inputEventDelay = 2000;
  public static EventNames = GA4Events;
  public static EventActions = GA4Actions;

  private static getDefaultData(gaEvent: IGAEvent) {
    return {
      event: gaEvent.event ?? 'GTM event To GA',
      GA_event_category: gaEvent.category,
      GA_event_action: gaEvent.action,
      GA_event_label: gaEvent.label,
      GA_event_value: gaEvent.value,
      AuctionID: gaEvent.auctionId ?? '',
      WorthyBuyerID: gaEvent.worthyBuyerId || this._buyerId,
    };
  }

  public static set buyerId(id: number) {
    this._buyerId = id;
  }

  public static sendGAActionData(gaEvent: IGAEvent): void {
    const event: DataLayerArgs = {
      dataLayer: this.getDefaultData(gaEvent),
    };

    TagManager.dataLayer(event);
  }

  public static sendGAEvent(gaEvent: {}): void {
    const event: TagManagerArgs = {
      gtmId: `GTM-${process.env.REACT_APP_GTM_ID}`,
      events: { ...gaEvent },
    };

    TagManager.initialize(event);
  }

  private static gtmToGA4Event(data: IGA4Event) {
    if (!data.buyerId) {
      console.error('Invalid buyer id');
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        ...ga4NullEventFields,
        event: data.eventName,
        ...data,
      },
    });
  }

  /**
   * @param data.eventName - interaction | feedback
   * @param data.eventName.interaction actions - open | success | failure | close
   * @param data.eventName.feedback actions - select | clear | focus | input | toggle | navigate | click
   * @param data.page2, section3 - automatically parsed from URL
   */
  public static GA4SendEvent(data: IGA4EventData) {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const { locationPrimary, locationSecondary } = getLocation();
    const location2 = data.location2 || locationPrimary;
    const location3 = data.location3 || locationSecondary;
    const isAuctionPage = location2 === 'auctionPage' || location2 === 'hamptonAuctions';

    if (!location2) {
      console.error('GA4 Event error: page was not provided!');
    }

    if (!isAuctionPage && !location3) {
      console.error('GA4 Event error: section was not provided!');
    }

    if (!Object.values(GA4Pages).includes(location2 as GA4Pages)) {
      console.error('GA4 Event error: invalid page');
    }

    if (!isAuctionPage && !Object.values(GA4Sections).includes(location3 as GA4Sections)) {
      console.error('GA4 Event error: invalid section');
    }

    const event = {
      eventName: data.eventName || GA4Events.Interaction,
      appVersion,
      buyerId: this._buyerId,
      ...data,
      location1: 'buyersPortal',
      location2: location2 as GA4Pages,
      location3: location3 as GA4Sections,
    } as const;

    if (process.env.REACT_APP_ENV !== 'prod') {
      console.table(event);
    }

    this.gtmToGA4Event(event);
  }

  public static GA4LoginEvent(
    actionedValue: 'password' | 'otpSMS' | 'otpEmail',
    action: 'success' | 'failure',
  ) {
    const event = {
      appVersion,
      eventName: GA4Events.Feedback,
      actionedObject: 'buyerLoginMethod',
      actionedValue,
      action,
      location1: 'authenticator',
      location2: 'login',
    } as const;

    if (process.env.REACT_APP_ENV !== 'prod') {
      console.table(event);
    }

    TagManager.dataLayer({
      dataLayer: {
        ...ga4NullEventFields,
        event: event.eventName,
        ...event,
      },
    });
  }

  // feedback actions
  public static GA4SendActionOpen(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      eventName: this.EventNames.Feedback,
      action: this.EventActions.Open,
    });
  }
  public static GA4SendActionSuccess(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      eventName: this.EventNames.Feedback,
      action: this.EventActions.Success,
    });
  }

  // interaction actions
  public static GA4SendActionNavigate(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Navigate,
    });
  }

  public static GA4SendActionInput(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Input,
    });
  }

  public static GA4SendActionSubmit(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Submit,
    });
  }

  public static GA4SendActionSelect(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Select,
    });
  }

  public static GA4SendActionToggle(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Toggle,
    });
  }

  public static GA4SendActionClear(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Clear,
    });
  }

  public static GA4SendActionSort(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Sort,
    });
  }

  // we should avoid using this
  public static GA4SendActionClick(data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>) {
    this.GA4SendEvent({
      ...data,
      action: this.EventActions.Click,
    });
  }

  // action to trigger correct input event
  // uses delay so it will fire event when user finished inputting data
  public static GA4InputChange(
    inputType: string,
    inputName: string,
    data: Partial<Omit<IGA4EventData, 'action' | 'eventName'>>,
  ) {
    if (this.inputDelays[inputName]) {
      window.clearTimeout(this.inputDelays[inputName]);
    }

    const delayedEvent = () => {
      if (inputType === 'checkbox' || inputType === 'toggle') {
        return window.setTimeout(() => {
          this.GA4SendActionToggle(data);
        }, this.inputEventDelay);
      }

      if (inputType === 'range' || inputType === 'select') {
        return window.setTimeout(() => {
          this.GA4SendActionSelect(data);
        }, this.inputEventDelay);
      }

      return window.setTimeout(() => {
        this.GA4SendActionInput(data);
      }, this.inputEventDelay);
    };

    this.inputDelays[inputName] = delayedEvent();
  }
}
